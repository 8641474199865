<template>
  <v-col
    cols="12"
    md="12"
  >
    <!-- <v-row
      no-gutters
      class="pb-4"
    >
      <v-col cols="auto">
        <v-btn
          class="mr-2"
          color="secondary"
        >
          Все
        </v-btn>
      </v-col>
      <v-col
        cols="auto"
        class="actions-bar"
      >
        <v-btn
          color="green"
          @click="createDialog"
        >
          Создать заказ
        </v-btn>
      </v-col>
    </v-row> -->

    <base-material-card
      color="warning"
      class="px-5 py-3"
    >
      <template v-slot:heading>
        <div class="text-h3 font-weight-light">Заказы</div>

        <div class="text-subtitle-1 font-weight-light">
          <h5>Общее количество: {{ ordersList.length }}</h5>
        </div>
      </template>
      <!-- <template v-slot:btns> sdsdsdsds </template>2 -->
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="ordersList"
          :loading="loading"
        >
          <!-- <template @click="alert()" v-slot:[`item`]> </template> -->

          <template v-slot:[`item.created_at`]="{ item }">
            <router-link
              style="color: unset; text-decoration: none"
              :to="`/orders/${item.id}`"
            >
              <span>
                {{ dateFormatter(item.created_at) }}
              </span>
            </router-link>
          </template>
          <template v-slot:[`item.price`]="{ item }">
            <router-link
              style="color: unset; text-decoration: none"
              :to="`/orders/${item.id}`"
            >
              <span>
                {{ item.price }}
              </span>
            </router-link>
          </template>
          <template v-slot:[`item.id`]="{ item }">
            <router-link
              style="color: unset; text-decoration: none"
              :to="`/orders/${item.id}`"
            >
              <span>
                {{ item.id }}
              </span>
            </router-link>
          </template>
          <template v-slot:[`item.delivery_street`]="{ item }">
            <router-link
              style="color: unset; text-decoration: none"
              :to="`/orders/${item.id}`"
            >
              <span>
                {{ item.delivery_street }}
              </span>
            </router-link>
          </template>
          <template v-slot:[`item.delivery_type`]="{ item }">
            <router-link
              style="color: unset; text-decoration: none"
              :to="`/orders/${item.id}`"
            >
              <span>
                {{ item.delivery_type }}
              </span>
            </router-link>
          </template>
          <template v-slot:[`item.street`]="{ item }">
            <router-link
              style="color: unset; text-decoration: none"
              :to="`/orders/${item.id}`"
            >
              <span>
                {{ item.street }}
              </span>
            </router-link>
          </template>
          <template v-slot:[`item.user_id`]="{ item }">
            <router-link
              style="color: unset; text-decoration: none"
              :to="`/orders/${item.id}`"
            >
              <span>
                {{ item.user_id }}
              </span>
            </router-link>
          </template>
          <!-- <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editDialog(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              class="mr-2"
              @click="setCourierDialog(item)"
            >
              mdi-plus
            </v-icon>
            <v-icon
              small
              color="red"
              @click="deleteDialog(item)"
            >
              mdi-delete
            </v-icon>
          </template> -->
        </v-data-table>
      </v-card-text>
      <template v-slot:btns>
        <div
          v-if="orders.total"
          class="pagination flex-row"
        >
          <p
            style="font-weith: 700; cursor: pointer"
            @click="currentPage = 1"
            class="pagination-firstPage"
          >
            Первая страница
          </p>
          <span
            class="prev-page"
            @click="getPage('prev')"
          >
            &#8249;
          </span>
          {{ currentPage }} из {{ orders.last_page }}
          <span
            class="next-page"
            @click="getPage('next')"
          >
            &#8250;
          </span>
          <div class="pagintaion-input">
            <form @submit.prevent="inputPagination">
              <input
                class="paginationInput"
                v-model="inputPage"
                type="text"
              />
            </form>
          </div>
          <p
            style="font-weith: 700; cursor: pointer"
            @click="currentPage = orders.last_page"
            class="pagination-lastPage"
          >
            Последняя страница
          </p>
        </div>
      </template>
    </base-material-card>
    <!-- Новый Заказ -->
    <!-- <v-dialog
      v-model="dialog"
      max-width="500px" 
    >
      <v-card>
        <v-card-title>
          <span class="text-h5"> Заказ </span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form
              v-model="valid"
              ref="form"
              lazy-validation
            >
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-select
                    v-model="dialogData.city_id"
                    :items="citiesList"
                    item-text="name"
                    item-value="id"
                    label="Город"
                    :error-messages="errors.city_id"
                    @change="errors.city_id = []"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-select
                    v-model="dialogData.client_id"
                    :items="clientsList"
                    item-text="name"
                    item-value="id"
                    label="Клиент"
                    :error-messages="errors.point_id"
                    @change="errors.point_id = []"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-select
                    v-model="dialogData.point_id"
                    :items="pointsList"
                    item-text="name"
                    item-value="id"
                    label="Торговая точка"
                    :error-messages="errors.point_id"
                    @change="errors.point_id = []"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-select
                    v-model="dialogData.payment_type"
                    :items="paymentTypesList"
                    item-text="name"
                    item-value="id"
                    label="Тип оплаты"
                    :error-messages="errors.payment_type"
                    @change="errors.payment_type = []"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-select
                    v-model="dialogData.delivery_method"
                    :items="deliveryMethodsList"
                    item-text="name"
                    item-value="id"
                    label="Способ доставки"
                    :error-messages="errors.delivery_method"
                    @change="errors.delivery_method = []"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-select
                    v-model="dialogData.status"
                    :items="orderStatusesList"
                    item-text="name"
                    item-value="id"
                    label="Статус"
                    :error-messages="errors.status"
                    @change="errors.status = []"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    v-model="dialogData.cost"
                    label="Стоимость"
                    :error-messages="errors.cost"
                    @input="errors.cost = []"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    v-model="dialogData.delivery_cost"
                    label="Стоимость доставки"
                    :error-messages="errors.delivery_cost"
                    @input="errors.delivery_cost = []"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    v-model="dialogData.check_number"
                    label="Номер чека"
                    :error-messages="errors.check_number"
                    @input="errors.check_number = []"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-select
                    v-model="dialogData.items_id"
                    :items="goodsList"
                    item-text="ru_name"
                    item-value="id"
                    label="Товары"
                    :error-messages="errors.items_id"
                    @change="errors.items_id = []"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-select
                    v-model="dialogData.courier_id"
                    :items="couriersList"
                    item-text="name"
                    item-value="id"
                    label="Курьеры"
                    :error-messages="errors.courier_id"
                    @change="errors.courier_id = []"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Отменить
          </v-btn>
          <v-btn
            v-if="actionMethod === 'edit'"
            color="blue darken-1"
            text
            :disabled="!valid"
            @click="editOrder"
          >
            Редактировать
          </v-btn>
          <v-btn
            v-if="actionMethod === 'create'"
            color="blue darken-1"
            text
            :disabled="!valid"
            @click="createOrder"
          >
            Создать
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <!-- действительно хотите удалить? -->
    <!-- <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="text-h5">Вы действительно хотите удалить?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogDelete = false"
          >
            Отменить
          </v-btn>
          <v-btn
            color="danger darken-1"
            text
            @click="deleteOrder"
          >
            Удалить
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <!-- Назначить курьера -->
    <!-- <v-dialog
      v-model="dialogCourier"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="text-h5">Назначить курьера</v-card-title>
        <v-card-actions>
          <v-col cols="6">
            <v-select
              v-model="courierData.courier_id"
              label="Список курьеров"
              :items="couriersList"
              item-text="name"
              item-value="id"
            ></v-select>
          </v-col>

          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogCourier = false"
            >Отменить</v-btn
          >
          <v-btn
            color="danger darken-1"
            text
            @click="setCourier"
          >
            Выбрать
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </v-col>
</template>

<script>
import OrdersService from '@/app/Services/OrdersService'
import TableDialogMixin from '@/app/Helpers/mixins/BaseTableDialog'
import dateFormatter from '@/app/Helpers/dateFormatter'
import GetClients from '@/app/Helpers/mixins/essences/getClients'
import GetGoods from '@/app/Helpers/mixins/essences/getGoods'
import GetUsers from '@/app/Helpers/mixins/essences/getUsers'

export default {
  name: 'OrdersDashboard',

  mixins: [TableDialogMixin, GetClients, GetGoods, GetUsers],

  data() {
    return {
      inputPage: '',
      currentPage: 1,
      dialogCourier: false,
      courierData: {},
      ordersList: [],
      headers: [
        {
          sortable: true,
          text: 'ID',
          value: 'id',
        },
        {
          sortable: false,
          text: 'Тип',
          value: 'delivery_type',
          align: 'left',
        },
        {
          sortable: true,
          text: 'Сумма заказа',
          value: 'price',
          align: 'left',
        },
        {
          sortable: false,
          text: 'Потрачено бонусов',
          value: 'bonus_waste',
          align: 'left',
        },
        {
          sortable: false,
          text: 'Улица',
          value: 'delivery_street',
          align: 'left',
        },
        {
          sortable: true,
          text: 'Клиент ID',
          value: 'user_id',
          align: 'right',
        },
        {
          sortable: false,
          text: 'Клиент',
          value: 'customer_name',
          align: 'right',
        },
        {
          sortable: false,
          text: 'Номер телефона',
          value: 'phone',
          align: 'right',
        },
        {
          sortable: true,
          text: 'Дата',
          value: 'created_at',
        },
        // {
        //   sortable: false,
        //   text: 'Действия',
        //   value: 'actions',
        // },
      ],
      dialogData: {
        city_id: null,
        client_id: null,
        point_id: null,
        payment_type: null,
        delivery_method: null,
        status: null,
        courier_id: null,
        cost: null,
        delivery_cost: null,
        check_number: null,
        items_id: null,
      },
    }
  },
  watch: {
    currentPage: {
      handler() {
        this.getOrdersPage(this.currentPage)
      },
    },
  },
  computed: {
    citiesList() {
      return this.$store.state.references.cities
    },

    pointsList() {
      return this.$store.state.references.points
    },

    paymentTypesList() {
      return this.$store.state.references.paymentTypes
    },

    deliveryMethodsList() {
      return this.$store.state.references.deliveryMethods
    },

    orderStatusesList() {
      return this.$store.state.references.orderStatuses
    },

    couriersList() {
      return this.$store.getters['user/couriers']
    },
  },

  async created() {
    this.loading = true
    await this.getOrders()
    this.loading = false

    // await this.getClients()
    // await this.getGoods()
    // await this.getUsers()
  },

  methods: {
    dateFormatter,
    async inputPagination() {
      const response = await OrdersService.getOrdersPage(this.inputPage)
      this.currentPage = this.inputPage
      this.ordersList = response.data?.orders.data
      this.orders = response.data?.orders
    },
    async getOrdersPage() {
      const response = await OrdersService.getOrdersPage(this.currentPage)
      this.ordersList = response.data?.orders.data
      this.orders = response.data?.orders
    },
    async getOrders() {
      const response = await OrdersService.getOrders()

      if (response.status) {
        this.ordersList = response.data?.orders.data
        this.orders = response.data?.orders

        this.$store.dispatch('orders/setOrdersData', response.data)
      }
    },
    getPage(type) {
      this.inputPage = ''
      if (type === 'next' && this.currentPage <= this.orders.total) {
        if (this.currentPage !== this.orders.last_page) {
          this.currentPage = this.currentPage + 1
        }
      }
      if (type === 'prev' && this.currentPage > 1) {
        this.currentPage = this.currentPage - 1
      }
    },

    // async createOrder() {
    //   if (this.$refs.form.validate()) {
    //     const response = await OrdersService.createOrder(this.dialogData)

    //     if (response.status) {
    //       await this.getOrders()
    //       this.dialog = false
    //     } else {
    //       this.errors = response.error
    //     }
    //   }
    // },

    // async editOrder() {
    //   if (this.$refs.form.validate()) {
    //     const response = await OrdersService.editOrder(this.selectedId, this.dialogData)

    //     if (response.status) {
    //       await this.getOrders()
    //       this.dialog = false
    //     } else {
    //       this.errors = response.error
    //     }
    //   }
    // },

    // async deleteOrder() {
    //   const response = await OrdersService.deleteOrder(this.selectedId)

    //   if (response.status) {
    //     await this.getOrders()
    //     this.dialogDelete = false
    //   } else {
    //     this.errors = response.error
    //   }
    // },

    // async setCourierDialog(item) {
    //   this.selectedId = item.id
    //   this.dialogCourier = true
    // },

    // async setCourier() {
    //   const response = await OrdersService.setCourierInOrder(this.selectedId, {
    //     courier_id: this.courierData.courier_id,
    //   })

    //   if (response.status) {
    //     await this.getOrders()
    //     this.dialogCourier = false
    //   }
    // },
  },
}
</script>
<style scoped>
.pagination-firstPage,
.pagination-lastPage {
  margin: 15px;
}
.paginationInput {
  border: 1px solid black;
}
.pagination {
  align-items: center;
  display: flex;
  position: relative;
  bottom: 73px !important;
  left: 20px !important;
}
.next-page,
.prev-page {
  font-size: 26px;
  font-weight: 600;
  cursor: pointer;
  padding: 8px;
}
</style>
